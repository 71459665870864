import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="আরবী" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  আরবী
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/arabic',
        name: 'আরবী',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <BookList books={[{
        title: 'হাত ঘুরিয়ে আরবী হরফ লিখি, সাথে শিখি দুইটা করে শব্দ (বই)',
        link: '/study-materials/arabic/arabic-letters-with-examples'
      }, {
        title: `আরবী বর্ণ চিনি ও আরবী পড়তে শিখি (কায়দা মাক্কিয়াহ'র প্রেসেন্টেশন)`,
        link: '/study-materials/arabic/arabic-letters-with-4-form-and-pictures'
      }, {
        title: 'ছবি দেখে আরবী শব্দ শেখা',
        link: '/study-materials/arabic/learn-arabic-by-watching-pictures'
      }]} mdxType="BookList" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      